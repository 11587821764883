<template>
  <div class="multi-places-end-point">
    <v-container class="d-flex flex-column justify-center grow">
      <!-- Loading -->
      <VuetifyContentLoading
        v-if="processingRequest"
        :loading="processingRequest"
        :label="resultText"
      />
      <!-- Content -->
      <template v-else>
        <!-- Icon -->
        <v-icon :color="iconColor" x-large>
          {{ iconName }}
        </v-icon>
        <!-- Title -->
        <h3 class="text-subtitle-1 text-center mt-2">{{ resultText }}</h3>
      </template>
    </v-container>
  </div>
</template>

<script>
// Components
import VuetifyContentLoading from '@/components/ui/VuetifyContentLoading'
// Mixins
import formMixin from '@/mixins/formMixin'
import uiMixin from '@/mixins/uiMixin'
// Vuelidate plugin
import { validationMixin } from 'vuelidate'
// Services
import { getFusionPlaceById, updateFusionPlaceById } from '../services/fusionPlaces'
import { getPlaceById, updatePlaceById } from '@/services/place'
import { getCompanyById, updateCompanyById } from '@/services/company'
import { getUserById, updateUserById, getUserByEmail } from '@/services/user'
// Vuex
import { mapGetters, mapActions } from 'vuex'
// Utils
import { cloneDeep, isNil } from 'lodash'
// Constants
import { FUSION_PLACES_STATUS } from '../constants'
import { ROLES } from '@/constants'

export default {
  name: 'MultiPlacesEndpoint',
  components: {
    VuetifyContentLoading
  },
  mixins: [formMixin, uiMixin, validationMixin],
  data() {
    return {
      key: this.$route.params.key || null, // fusionPlaceKey
      resultText: 'Comprobando Fusión',
      processingRequest: true,
      iconName: 'mdi-check-circle',
      iconColor: 'green darken-2'
    }
  },
  computed: {
    ...mapGetters('authentication', ['userData']),
    ...mapGetters('company', ['companyData']),
    ...mapGetters('place', ['placeData'])
  },
  async mounted() {
    await this.processFusion()
  },
  methods: {
    ...mapActions('authentication', ['getUser']),
    ...mapActions('company', ['getCompany']),
    ...mapActions('place', ['getPlace']),
    /**
     * Proceso de activación de fusion place
     */
    async processFusion() {
      try {
        // Datos de la fusión
        const fusionPlaceData = await getFusionPlaceById(this.key)

        // Se comprueba que la activación está pendiente y que se
        // trata del usuario (cuenta) a la que iba dirigida la fusión
        if (
          fusionPlaceData &&
          fusionPlaceData.userKeyTarget === this.userData.id &&
          fusionPlaceData.status === FUSION_PLACES_STATUS.pending
        ) {
          // Datos del usuario objetivo (target - propietario actual)
          const targetUserData = cloneDeep(this.userData)
          const targetCompanyData = cloneDeep(this.companyData)
          const targetPlaceData = await getPlaceById(fusionPlaceData.placeKeyTarget)
          const targetUserHasOnlyOnePlace =
            !isNil(targetUserData.places) &&
            typeof targetUserData.places === 'object' &&
            Object.keys(targetUserData.places).length === 1

          // Datos del usuario origen (source - el que realiza la petición)
          const sourceUserDataAuth = await getUserByEmail(fusionPlaceData.emailSource)
          const sourceUserData = await getUserById(sourceUserDataAuth.uid)
          const sourceCompanyData = await getCompanyById(Object.keys(sourceUserData.companies)[0])

          // Relacionamos usuario origen (realiza la petición) el establecimiento destino
          await updateUserById({
            id: sourceUserData.id,
            places: Object.keys(sourceUserData.places).reduce(
              (accPlaceIds, placeId) => {
                accPlaceIds[placeId] = true
                return accPlaceIds
              },
              { [targetPlaceData.id]: true }
            )
          })
          // Relacionamos a la compañía origen el establecimiento destino.
          // También podría relacionarse el usuario destino, si así fuera.
          await updateCompanyById({
            id: sourceCompanyData.id,
            places: Object.keys(sourceCompanyData.places).reduce(
              (accPlaceIds, placeId) => {
                accPlaceIds[placeId] = true
                return accPlaceIds
              },
              { [targetPlaceData.id]: true }
            ),
            users: Object.keys(sourceCompanyData.users).reduce(
              (accUserIds, userId) => {
                accUserIds[userId] = true
                return accUserIds
              },
              targetUserHasOnlyOnePlace ? { [sourceUserData.id]: true } : {}
            )
          })
          // Relacionamos el establecimiento destino al usuario y compañía origen.
          // También habría que mantener al usuario objetivo, si este no posee más
          // establecimientos que el actual, con el nuevo rol de MANAGER
          await updatePlaceById({
            id: targetPlaceData.id,
            companies: {
              [sourceCompanyData.id]: true
            },
            users: Object.keys(targetPlaceData.users).reduce(
              (accUserIds, userId) => {
                if (targetUserHasOnlyOnePlace || userId !== targetUserData.id) {
                  accUserIds[userId] = true
                }
                return accUserIds
              },
              { [sourceUserData.id]: true }
            )
          })
          // Eliminamos de la compañía destino el establecimiento destino
          // Y si así también fuera el usuario origen
          await updateCompanyById({
            id: targetCompanyData.id,
            places: Object.keys(targetCompanyData.places).reduce((accPlaceIds, placeId) => {
              if (placeId !== targetPlaceData.id) {
                accPlaceIds[placeId] = true
              }
              return accPlaceIds
            }, {})
          })
          // Al usuario objetivo lo desvinculamos del establecimiento objetivo.
          // También puede darse el caso de eliminarlo de su antigua compañía
          // y de cambiar ciertos atributos
          const extraAttributesTargetUserData = targetUserHasOnlyOnePlace
            ? {
                role: ROLES.horeca_manager.value,
                companies: {
                  [sourceCompanyData.id]: true
                }
              }
            : {
                places: Object.keys(targetUserData.places).reduce((accPlaceIds, placeId) => {
                  if (placeId !== targetPlaceData.id) {
                    accPlaceIds[placeId] = true
                  }
                  return accPlaceIds
                }, {})
              }
          // Actualizamos usuario objetivo
          await updateUserById({
            ...targetUserData,
            ...extraAttributesTargetUserData
          })

          // Actualizamos usuario en STORE
          await this.getUser(targetUserData.id)
          // Actualizamos compañía en STORE
          await this.getCompany(
            targetUserHasOnlyOnePlace ? sourceCompanyData.id : targetCompanyData.id
          )
          // Actualizamos establecimiento en STORE
          if (fusionPlaceData.placeKeyTarget === targetPlaceData.id) {
            await this.getPlace(targetPlaceData.id)
          }

          // Se cambia de estado la fusión
          await updateFusionPlaceById({
            id: this.key,
            status: FUSION_PLACES_STATUS.done
          })

          this.resultText = 'Se ha validado correctamente la fusión'
        } else {
          throw new Error('Hubo un error al intentar realizar la fusión')
        }
      } catch (error) {
        this.iconName = 'mdi-block-helper'
        this.iconColor = 'orange darken-2'
        this.resultText = 'No se pudo activar la fusión'
      } finally {
        this.processingRequest = false
      }
    }
  }
}
</script>
<style lang="scss">
.multi-places-end-point {
  min-height: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
}
</style>
